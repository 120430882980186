<template>
  <v-container>
    <v-row align="center">
      <v-spacer />
      <v-btn @click="classify()" plain>items</v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="articulos"
      disable-pagination
      dense
      :hide-default-footer="true"
    >
      <template v-slot:[`item.STATUS`]="{ item }">
        <!--  -->
        <v-checkbox
          v-model="item.STATUS"
          indeterminate
          @change="getItems(item.id, item.STATUS)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import AdminDataService from "../../services/AdminDataService";
export default {
  name: "clasif",
  data: () => ({
    articulos: [],
    headers: [
      { text: "Empresa", value: "EMPRESA", align: "start", sortable: true },
      { text: "Almacen", value: "ALMACEN", align: "start", sortable: false },
      { text: "Código", value: "CODIGO", sortable: true },
      { text: "Descripción", value: "DESC_CODIGO", sortable: false },
      { text: "Saldo", value: "SALDO", align: "center", sortable: false },
      { text: "Repuesto?", value: "STATUS", align: "center", sortable: false },
    ],
  }),
  methods: {
    getItems(id, status) {
      // console.log(this.articulos)
      let data = {};
      data["id"] = id;
      data["status"] = status;
      data["rev"] = true;
      AdminDataService.updateCode(data).then((response) => {
        console.log(response.data);
      });
    },
    getUnclass() {
      const status = "2";
      AdminDataService.getUnclass(status).then((response) => {
        this.articulos = response.data.map(this.getDisplayCode);
      });
    },
    classify() {
      console.log(this.articulos);
    },
    getDisplayCode(codigos) {
      return {
        id: codigos.id,
        CODIGO: codigos.CODIGO,
        EMPRESA: codigos.DESC_EMPRESA,
        DESC_CODIGO: codigos.DESC_CODIGO,
        ALMACEN: codigos.ALMACEN,
        // CRITICIDAD: codigos.CRITICIDAD,
        SALDO: codigos.SALDO,
        // MIN_STK: codigos.MIN_STK,
        // ALM_COD: codigos.ALM_COD,
        STATUS: undefined,
      };
    },
  },
  mounted() {
    this.getUnclass();
  },
};
</script>
