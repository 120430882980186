import http from "../http-common";
import authHeader from './Auth-header';

class CodeDataService {
  getUnclass(params) {
    return http.get(`/critcod/${params}`, { headers: authHeader()});
  }
  updateCode(data) {
    return http.post('/status', data , {headers: authHeader()})
  }
  getUsers() {
    return http.get('/users/', {headers : authHeader()})
  }
  getUser(id) {
    return http.get(`/user/${id}`, {headers: authHeader()});
  }
  newUser(data) {
    return http.post('/auth/signup/', data, {headers: authHeader()})
  }
  deleteUser (data) {
    return http.post('/user/delete/', data, {headers: authHeader()})
  }
}

export default new CodeDataService();